$(document).ready(function () {

    new Swiper ('.example-slider .swiper-container', {
        navigation: {
            nextEl: '.example-slider .swiper-button-next',
            prevEl: '.example-slider .swiper-button-prev',
        },
        slidesPerView: 2,
        spaceBetween: 30,
        breakpoints: {
            // when window width is <= 575px
            768: {
                slidesPerView: 1,
                spaceBetweenSlides: 30
            }
        }
    });

    new Swiper ('.about-slider .swiper-container', {
        navigation: {
            nextEl: '.about-slider .swiper-button-next',
            prevEl: '.about-slider .swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        slidesPerView: 1,
        spaceBetween: 30
    });

    new Swiper ('.gallery-slider .swiper-container', {
        navigation: {
            nextEl: '.gallery-slider .swiper-button-next',
            prevEl: '.gallery-slider .swiper-button-prev',
        },
        slidesPerView: 1,
        spaceBetween: 30
    });
});



let app = {

    initial: function () {

        // menu open function
        this.menuOpenFunc();

    },

    menuOpenFunc: function () {
        $('.hamburger').on('click', function () {
            let $navList = $('.head-nav-list');
            $(this).parent().toggleClass('active');
            if (!$navList.hasClass('active')) {
                $navList.addClass('active').removeClass('hidden');
            } else {
                $navList.removeClass('active');
                setTimeout(function () {
                    $navList.addClass('hidden');
                }, 450);
            }
        });
    }

};

(function () {
    app.initial();
})();

